<template>
  <div>
    <div
      id="offcanvasChapters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible, 'offcanvas-end--big-panel': user.roles.includes('super-admin') }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="settings" /></span>
          <slot
            name="title"
          />
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <slot
        name="create-alert"
      />
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  computed: {
    ...mapGetters({
      visible: 'modals/createAlert',
      user: 'auth/admin',
    }),
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleCreateItem', false)
    },
  },
}
</script>
