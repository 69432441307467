<template>
  <!-- BEGIN: Content-->
  <div>
    <div class="offcanvas-body">
      <div class="row">
        <div
          v-if="user.id && user.roles.includes('super-admin')"
          class="col-8"
        >
          <Audience
            :show-cae="false"
            :is-alerts="true"
            :data="{ ...alert.audience }"
            :all-researchers-aux="alert.all_users"
            @saveAudience="saveAudience"
          />
        </div>
        <div :class="{'col-4': user.roles.includes('super-admin'), 'col-12': !user.roles.includes('super-admin')}">
          <div class="mb-1 offcanvas-body--view--title">
            <p class="mb-0">
              {{ alert.type && alert.type.value == 1 ? 'Start' : '' }} Date
            </p>
            <date-picker
              v-model="alert.begin_date"
              format="YYYY-MM-DD HH:mm"
              class="w-100"
              type="datetime"
              value-type="format"
              :show-hour="true"
              :show-minute="true"
              :disabled-date="disabledRange"
            />
          </div>
          <div
            v-if="alert.type && alert.type.value == 1"
            class="mb-1 offcanvas-body--view--title"
          >
            <p class="mb-0">
              End Date (optional)
            </p>
            <date-picker
              v-model="alert.end_date"
              format="YYYY-MM-DD HH:mm"
              class="w-100"
              type="datetime"
              value-type="format"
              :show-hour="true"
              :show-minute="true"
              :disabled-date="disabledRange"
            />
          </div>
          <hr>
          <div
            class="mb-1"
          >
            <label
              for="areaseniorcall"
              class="form-label"
            >Type</label>
            <v-select
              v-model="alert.type"
              label="label"
              :options="[
                {label: 'Task', value: 1},
                {label: 'Alarm', value: 2},
              ]"
              :get-option-key="option => option.value"
            />
          </div>
          <div class="mb-1">
            <label
              for="areaseniorcall"
              class="form-label"
            >Title</label>
            <input
              v-model="alert.title"
              class="form-control"
              type="text"
            >
          </div>
          <div class="mb-1">
            <label
              for="areaseniorcall"
              class="form-label"
            >Message</label>
            <quill-editor
              v-model="alert.message"
            />
          </div>
          <div class="mb-1 small-dropzone">
            <label
              for="areaseniorcall"
              class="form-label"
            >
              Attached file(s) (Total size: {{ totalSizeFiles }}MB)
            </label>
            <vue-dropzone
              id="dropzone"
              ref="myVueDropzone"
              :options="dropzoneOptions"
              @vdropzone-file-added="handleFileAdded"
            />
          </div>
          <div class="row">
            <div :class="{'col-5': alert.type && alert.type.value == 1, 'col-12': !alert.type || alert.type.value != 1 }">
              <div class="mb-1">
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    id="sendemail"
                    v-model="alert.send_mail"
                    type="checkbox"
                    value="View Archived"
                    class="form-check-input"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for="sendemail"
                  >
                    <small class="ms-1">Send alert by mail</small>
                  </label>
                </div>
              </div>
              <div class="mb-1">
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    id="sendemail"
                    v-model="alert.send_intranet"
                    type="checkbox"
                    value="View Archived"
                    class="form-check-input"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for="sendemail"
                  >
                    <small class="ms-1">Send alert by intranet</small>
                  </label>
                </div>
              </div>
              <div class="mb-1">
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    id="sendemail"
                    v-model="alert.show_calendar"
                    type="checkbox"
                    value="View Archived"
                    class="form-check-input"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for="sendemail"
                  >
                    <small class="ms-1">Show in calendar</small>
                  </label>
                </div>
              </div>
            </div>
            <div
              v-if="alert.type && alert.type.value == 1"
              class="col-7"
            >
              <div
                v-for="reminder, index in alert.reminders"
                :key="index"
                class="mb-1"
              >
                <div class="d-flex">
                  <label
                    class="form-check-label me-1"
                    for="sendemail"
                  >
                    <small class="ms-1">Reminder</small>
                  </label>
                  <input
                    v-model="reminder.days"
                    class="form-control"
                    type="number"
                    min="1"
                  >
                </div>
              </div>
              <button
                class="btn btn-secondary"
                @click="alert.reminders.push({})"
              >
                <i data-feather="plus" />
                <span class="ms-25 align-middle">Add reminder</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer mt-auto">
      <button
        v-if="!sending"
        type="button"
        class="btn btn-dark mb-1 d-grid w-100"
        @click="submit"
      >
        Create
      </button>
      <button
        v-if="sending"
        class="btn btn-dark mb-1 w-100"
        type="button"
        disabled=""
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        <span class="ms-25 align-middle">Creating...</span>
      </button>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import Audience from '../../labour-hazards/components/Audience.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    Audience,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      alert: {
        reminders: [],
        files: [],
        send_mail: true,
        send_intranet: true,
        show_calendar: true,
      },
      sending: false,
      dropzoneOptions: {
        url: '#',
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        autoProcessQueue: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      user: 'auth/admin',
    }),
    totalSizeFiles() {
      return this.alert.files.reduce((total, file) => total + file.size, 0) / 1000000
    },
  },
  async mounted() {
    // console.log(this.alert)
  },
  methods: {
    disabledRange(date) {
      return date <= new Date()
    },
    async submit() {
      this.sending = true
      await this.$store.dispatch('alerts/create', this.alert)
      this.$store.dispatch('modals/toggleCreateItem', false)
      this.alert = {
        reminders: [],
        files: [],
        send_mail: true,
        send_intranet: true,
        show_calendar: true,
      }
      this.sending = false
      this.$toastr.success('', 'New alert created')
    },
    saveAudience(audience) {
      this.alert.audience = audience
    },
    handleFileAdded(file) {
      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        size: file.size,
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
      }

      this.alert.files.push(fileData)
      if (this.totalSizeFiles > 20) {
        this.$refs.myVueDropzone.dropzone.removeFile(file)
        this.alert.files.splice(-1, 1)
        this.$toastr.error('', 'The combination of file sizes exceeding 20MB!')
      }
    },
  },
}
</script>
